import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";

import OnlinePaymentStore from "../../Stores/OnlinePaymentStore";
import ContractStore from "../../Stores/ContractStore";
import PaymentStore from "../../Stores/PaymentStore";
import LoaderStore from "../../Stores/LoaderStore";

import { SelectPolicy } from "./OnlinePaymentSelectPolicy";
import { OnlinePaymentMethodDetails } from "./OnlinePaymentMethodDetails";

import "../../Css/OnlinePayment/Online-payment.css";

const ChangeCardStep1 = () => {
  const { t } = useTranslation();

  const { paymentList } = useContext(PaymentStore);
  const contractStore = useContext(ContractStore);
  const { contractList, paymentMethodDetails, isLoading } = contractStore;
  const onlinePaymentStore = useContext(OnlinePaymentStore);
  const loaderStore = useContext(LoaderStore);

  const [selectedContract, setSelectedContract] = useState();
  const [paymentMethodDetail, setPaymentMethodDetail] = useState(undefined);
  const [pendingText, setPendingText] = useState(undefined);

  const eligibleContracts = contractList.filter(
    (contract) => contract.IsEligibleForChangeCard,
  );

  function checkPendingActions(contractNumber) {
    if (!contractNumber) {
      return;
    }

    if (
      eligibleContracts.some(
        (contract) =>
          contract.DisplayNumber === contractNumber &&
          contract.PaymentMethodId !== "C",
      )
    ) {
      loaderStore.setLoaderForData(false);
      return t("online.payment.change-card-pending-msg");
    }

    if (
      paymentList.some(
        (contract) =>
          contract.DisplayNumber === contractNumber &&
          contract.Payments.some((payment) => payment.StatusCode === "O"),
      )
    ) {
      loaderStore.setLoaderForData(false);
      return t("online.payment.change-card-fix-msg");
    }
  }

  useEffect(() => {
    const currentPaymentMethodDetail = paymentMethodDetails?.find(
      (item) => item[selectedContract],
    );

    setPaymentMethodDetail(currentPaymentMethodDetail);
  }, [paymentMethodDetails, selectedContract]);

  useEffect(() => {
    loaderStore.setLoaderForData(isLoading);
  }, [isLoading, loaderStore]);

  function storeResult(contractNumber) {
    const contract = eligibleContracts.find(
      (payment) => payment.DisplayNumber === contractNumber,
    );
    const existingPayment = paymentList.find(
      (payment) => payment.DisplayNumber === contractNumber,
    );
    const onlinePayment = {
      ContractNumber: contractNumber,
      OutstandingAmount: 0,
      PaymentMethodId: contract.PaymentMethodId,
      ProductCode: contract.ProductCode,
      PaymentFrequencyId: contract.PaymentFrequencyId,
      ApplicationNumber: contract.ApplicationNumber,
      MaskedAccountNumber: paymentMethodDetail?.MaskedAccountNumber,
      ExpirationDate: paymentMethodDetail?.ExpirationDate,
    };
    const payment = existingPayment
      ? {
          ...existingPayment,
          ...onlinePayment,
        }
      : {
          ...onlinePayment,
          PaymentCodeA: paymentMethodDetail?.AccountNumber,
          ApplicationNumber: contract.ApplicationNumber,
        };

    onlinePaymentStore.setContract(contract);
    onlinePaymentStore.setPayment(payment);
  }

  function handleSelect(selectedContractNumber) {
    const pendingActions = checkPendingActions(selectedContractNumber);

    setPendingText(pendingActions);
    setSelectedContract(selectedContractNumber);
    setPaymentMethodDetail(undefined);

    const alreadyFetched = paymentMethodDetails?.find(
      (item) => item[selectedContractNumber],
    );

    if (pendingActions || alreadyFetched) {
      return;
    }

    const {
      ApplicationNumber,
      DisplayNumber,
      Number,
      SystemApplicationCode,
      ProductCode,
    } = eligibleContracts.find(
      ({ DisplayNumber }) => DisplayNumber === selectedContractNumber,
    );

    const contractData = {
      contractNumber: Number,
      applicationNumber: ApplicationNumber,
      systemApplicationCode: SystemApplicationCode,
      productCode: ProductCode,
      displayNumber: DisplayNumber,
    };

    loaderStore.setLoaderForData(true);
    contractStore.loadContractBankAccountDetails(contractData);

    storeResult(selectedContractNumber);
  }

  return (
    <div>
      <SelectPolicy
        items={eligibleContracts}
        current={selectedContract}
        onSelect={handleSelect}
      />

      <div className="online-payment-form">
        <div className="form-element right-padded"></div>
        {pendingText && (
          <>
            <div className="form-element right-padded">
              <span className="payBold">{t("contracts.insurance-number")}</span>
              <span> {selectedContract} </span>
            </div>
            <div className="form-element right-padded">
              <span className="pending-text">{pendingText}</span>{" "}
            </div>
          </>
        )}
        {paymentMethodDetail && (
          <OnlinePaymentMethodDetails
            paymentMethod={paymentMethodDetail}
            contractNumber={selectedContract}
          />
        )}
      </div>
    </div>
  );
};

export default observer(ChangeCardStep1);
