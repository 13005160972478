import { action, observable, makeObservable } from "mobx";
import { createContext } from "react";
import agent from "../Api/agent";
import i18n from "../i18n";

class ContractStore {
  contractList = [];
  contractPRList = [];
  selectedContractDetails = {};
  contractHeader = [];
  promosList = [];
  paymentMethodDetails = undefined;
  showOnlinePayment = null;
  contractsHasContent = false;
  contractOverviewList = [];
  showCoveragesStore = false;
  showBillStore = false;
  showAgentStore = false;
  billHasContent = false;
  coverageHasContent = false;
  hasAtLeastOneGroupPolicy = false;
  hasOnlyGroupPolicies = false;
  groupContractsCoveragesHasContent = false;
  groupContractsCoverages = [];
  hasGroupPensionAnalysisData = false;
  groupPensionAnalysisData = [];
  hasGroupPensionCertificatesData = false;
  groupPensionCertificatesData = [];
  portfolioIsShown = false;
  groupPensionCertificatesErrorMsg = undefined;
  isLoading = false;

  constructor() {
    makeObservable(this, {
      contractList: observable,
      selectedContractDetails: observable,
      contractHeader: observable,
      promosList: observable,
      showOnlinePayment: observable,
      contractsHasContent: observable,
      contractOverviewList: observable,
      showCoveragesStore: observable,
      showBillStore: observable,
      showAgentStore: observable,
      billHasContent: observable,
      coverageHasContent: observable,
      hasAtLeastOneGroupPolicy: observable,
      hasOnlyGroupPolicies: observable,
      paymentMethodDetails: observable,
      groupContractsCoveragesHasContent: observable,
      hasGroupPensionAnalysisData: observable,
      groupPensionAnalysisData: observable,
      hasGroupPensionCertificatesData: observable,
      groupPensionCertificatesData: observable,
      portfolioIsShown: observable,
      contractPRList: observable,
      groupPensionCertificatesErrorMsg: observable,
      isLoading: observable,

      loadContracts: action,
      loadContractBankAccountDetails: action,
      // loadContractDetail: action,
      deloadContractDetail: action,
      loadSelectedContractDetails: action,
      loadGroupContractsCoverages: action,
      loadGroupContractCoverages: action,
      loadCoveragesFromApi: action,
      loadCoverages: action,
      setShowCoverageStore: action,
      loadBill: action,
      setShowBillStore: action,
      loadAgent: action,
      setShowAgentStore: action,
      loadGroupPensionAnalysisData: action,
      loadGroupPensionCertificatesData: action,
    });
  }

  loadContracts = () => {
    this.contractsHasContent = false;
    const contracts = agent.contracts.list();
    contracts
      .then((result) => {
        const contractsObj = { ...result };
        if (contractsObj.Contracts) {
          this.contractList = [...contractsObj.Contracts];
          this.contractPRList = [
            ...contractsObj.Contracts.filter((el) => el.StatusId === "PR"),
          ];
          // this.contractList[0].IsGroupPensionPolicy = true;
          let list = [...contractsObj.Contracts];
          let filteredCOntractListByDateAndDateNotNull = list.filter(
            (c) =>
              (c.StatusId === "IF" || c.StatusId === "PU") &&
              c.NextBillingDate !== null,
          );
          filteredCOntractListByDateAndDateNotNull.sort((a, b) =>
            b.NextBillingDate < a.NextBillingDate ? 1 : -1,
          );
          let filteredCOntractListByDateAndDateNull = list.filter(
            (c) =>
              (c.StatusId === "IF" || c.StatusId === "PU") &&
              c.NextBillingDate === null,
          );
          filteredCOntractListByDateAndDateNull.map(
            (elementWithNullDatem, i) => {
              filteredCOntractListByDateAndDateNotNull.push(
                elementWithNullDatem,
              );
            },
          );
          this.contractOverviewList = filteredCOntractListByDateAndDateNotNull;
          // this.contractOverviewList[0].IsGroupPensionPolicy = true;
        } else {
          this.contractList = [];
          this.contractPRList = [];
          this.contractOverviewList = [];
        }
        if (contractsObj.Categories) {
          this.contractHeader = [...contractsObj.Categories];
        } else {
          this.contractHeader = [];
        }
        if (contractsObj.ProductPromotionalSlides) {
          this.promosList = [...contractsObj.ProductPromotionalSlides];
        } else {
          this.promosList = [];
        }
        if (contractsObj.HealthContractsAny) {
          this.showOnlinePayment = contractsObj.HealthContractsAny;
        } else {
          this.showOnlinePayment = false;
        }
        if (contractsObj.GroupPoliciesAny) {
          this.hasAtLeastOneGroupPolicy = contractsObj.GroupPoliciesAny;
        } else {
          this.hasAtLeastOneGroupPolicy = false;
        }
        if (contractsObj.HasOnlyGroupPolicies) {
          this.hasOnlyGroupPolicies = contractsObj.HasOnlyGroupPolicies;
        } else {
          this.hasOnlyGroupPolicies = false;
        }

        const hasContractsForPortfolio = contractsObj.Contracts.some(
          (contract) =>
            contract.IsPortfolio === true &&
            ["IF", "PU"].includes(contract.StatusId),
        );

        if (hasContractsForPortfolio) {
          this.portfolioIsShown = true;
        } else {
          this.portfolioIsShown = false;
        }
        this.contractsHasContent = true;
      })
      .catch((error) => {
        this.contractsHasContent = true;
      });
  };

  loadContractBankAccountDetails = async (contractData) => {
    try {
      this.isLoading = true;
      const initialPaymentMethodDetails = this.paymentMethodDetails || [];
      const { displayNumber, ...bankAccountPayload } = contractData;
      const bankAccountDetailsQueryString = new URLSearchParams(
        bankAccountPayload,
      ).toString();

      const accountDetails = await agent.contracts.bankAccountDetails(
        bankAccountDetailsQueryString,
      );

      this.paymentMethodDetails = [
        ...initialPaymentMethodDetails,
        { [displayNumber]: accountDetails },
      ];
      this.isLoading = false;
    } catch {
      this.isLoading = false;
      this.paymentMethodDetails = [];
    }
  };

  loadGroupContractsCoverages = () => {
    const groupContracts = this.contractList.filter(
      (contract) => contract.IsGroupPolicy,
    );
    if (groupContracts.length > 0) {
      Promise.all(
        groupContracts.map(async (contract) => {
          const contractDetails = await agent.contractCoverages.getCoverage(
            contract.Number,
            contract.ProductCode,
            contract.ProductCategoryId,
          );
          return {
            number: contract.Number,
            productCode: contract.ProductCode,
            productCategoryId: contract.ProductCategoryId,
            productName: contract.ProductName,
            policyholderSurname: contract.PolicyholderSurname,
            details: contractDetails,
          };
        }),
      ).then((result) => {
        this.groupContractsCoverages = result;
        this.groupContractsCoveragesHasContent = true;
      });
    }
  };

  loadSelectedContractDetails = (details, isGroupPolicy) => {
    if (details.Coverages) {
      this.selectedContractDetails.Coverages = [...details.Coverages];
      if (isGroupPolicy) {
        this.selectedContractDetails.GroupPolicyMembers = [];
        const map = new Map();
        this.selectedContractDetails.Coverages.forEach((member) => {
          if (!map.has(member.InsuredId)) {
            map.set(member.InsuredId, true);
            this.selectedContractDetails.GroupPolicyMembers.push({
              InsuredId: member.InsuredId,
              NelisId: member.NelisId,
              PersonName: member.PersonName,
              PersonSurname: member.PersonSurname,
              PersonRole: member.PersonRole,
            });
          }
        });
      }
    }
    if (details.Benefits) {
      this.selectedContractDetails.Benefits = [...details.Benefits];
    }
    if (details.ExtraBenefitsLinks) {
      this.selectedContractDetails.ExtraBenefitsLinks = [
        ...details.ExtraBenefitsLinks,
      ];
    }
    if (details.Clauses) {
      this.selectedContractDetails.Clauses = [...details.Clauses];
    }
  };

  loadGroupContractCoverages = (contractId, productCode, productCategoryId) => {
    const selectedGroupContract = this.groupContractsCoverages.find(
      (contract) =>
        contract.number === contractId &&
        contract.productCode === productCode &&
        contract.productCategoryId === productCategoryId,
    );
    if (selectedGroupContract) {
      const selectedGroupContractDetails = selectedGroupContract.details;
      this.loadSelectedContractDetails(selectedGroupContractDetails, true);
      this.showCoveragesStore = true;
      this.coverageHasContent = true;
    }
  };

  loadCoveragesFromApi = (
    contractId,
    productCode,
    productCategoryId,
    isGroupPolicy,
  ) => {
    const coveragesResponse = agent.contractCoverages.getCoverage(
      contractId,
      productCode,
      productCategoryId,
    );
    coveragesResponse.then((result) => {
      this.loadSelectedContractDetails(result, isGroupPolicy);
      if (isGroupPolicy) {
        this.groupContractsCoverages.push({
          number: contractId,
          productCode,
          productCategoryId,
          details: result,
        });
      }

      this.showCoveragesStore = true;
      this.coverageHasContent = true;
    });
  };

  loadCoverages = (
    contractId,
    productCode,
    productCategoryId,
    isGroupPolicy = false,
  ) => {
    this.coverageHasContent = false;

    if (this.groupContractsCoveragesHasContent && isGroupPolicy) {
      this.loadGroupContractCoverages(
        contractId,
        productCode,
        productCategoryId,
      );
    }

    if (!this.coverageHasContent) {
      this.loadCoveragesFromApi(
        contractId,
        productCode,
        productCategoryId,
        isGroupPolicy,
      );
    }
  };

  loadBill = (contractId, productCode, productCategoryId) => {
    this.billHasContent = false;
    const billResponse = agent.contractBill.getBill(
      contractId,
      productCode,
      productCategoryId,
    );
    billResponse.then((result) => {
      // console.log(result, "result loadbill");
      if (result.DocumentTypes) {
        this.selectedContractDetails.DocumentTypes = [...result.DocumentTypes];
      }
      if (result.InvestmentStatementLink) {
        this.selectedContractDetails.InvestmentStatementLink = {
          ...result.InvestmentStatementLink,
        };
      }
      if (result.OsekaInvestmentLinks) {
        this.selectedContractDetails.OsekaInvestmentLinks = [
          ...result.OsekaInvestmentLinks,
        ];
      }
      if (result.Transactions) {
        this.selectedContractDetails.Transactions = [...result.Transactions];
      }
      if (result.AccountValue) {
        this.selectedContractDetails.AccountValue = result.AccountValue;
      }
      if (result.DeathAmount) {
        this.selectedContractDetails.DeathAmount = result.DeathAmount;
      }
      if (result.Dividend) {
        this.selectedContractDetails.Dividend = result.Dividend;
      }
      if (result.ExpiredAmount) {
        this.selectedContractDetails.ExpiredAmount = result.ExpiredAmount;
      }
      if (result.LoanAmount) {
        this.selectedContractDetails.LoanAmount = result.LoanAmount;
      }
      if (result.LoanRight) {
        this.selectedContractDetails.LoanRight = result.LoanRight;
      }
      if (result.LoanToRepay) {
        this.selectedContractDetails.LoanToRepay = result.LoanToRepay;
      }
      if (result.SurrenderValue) {
        this.selectedContractDetails.SurrenderValue = result.SurrenderValue;
      }
      if (result.VisibleFields) {
        this.selectedContractDetails.VisibleFields = result.VisibleFields;
      }
      if (result.CurrencyCode) {
        this.selectedContractDetails.CurrencyCode = result.CurrencyCode;
      }
      this.showBillStore = true;
      this.billHasContent = true;
    });
  };

  loadAgent = (contractId) => {
    const agentResponse = agent.contractAgent.getAgent(contractId);
    agentResponse.then((result) => {
      this.selectedContractDetails.Agent = { ...result };
      this.showAgentStore = true;
    });
  };

  setShowAgentStore = () => {
    this.showAgentStore = false;
  };

  setShowBillStore = () => {
    this.showBillStore = false;
    this.billHasContent = false;
  };

  setShowCoverageStore = () => {
    this.showCoveragesStore = false;
    this.coverageHasContent = false;
  };

  deloadContractDetail = () => {
    this.selectedContractDetails = {};
  };

  loadGroupPensionCertificatesData = (contractId) => {
    this.hasGroupPensionCertificatesData = false;
    const agentResponse =
      agent.contractGroupPension.getCertificates(contractId);

    agentResponse
      .then((result) => {
        this.groupPensionCertificatesData = result.Certificates;
        this.hasGroupPensionCertificatesData = true;
      })
      .catch((ex) => {
        this.groupPensionCertificatesErrorMsg = i18n.t(
          "global.service-no-available",
        );
      })
      .finally(() => {
        this.hasGroupPensionCertificatesData = true;
      });
  };

  loadGroupPensionAnalysisData = (contractId) => {
    this.hasGroupPensionAnalysisData = false;
    const groupPensionAnalysisDataElement = this.groupPensionAnalysisData.find(
      (elem) => elem.contractId === contractId,
    );
    if (groupPensionAnalysisDataElement) {
      this.hasGroupPensionAnalysisData = true;
    } else {
      const agentResponse =
        agent.contractGroupPension.getBillAnalysis(contractId);
      agentResponse
        .then((result) => {
          const newArray = [...this.groupPensionAnalysisData];
          newArray.push({ contractId, ...result });
          this.groupPensionAnalysisData = [...newArray];
          this.hasGroupPensionAnalysisData = true;
        })
        .catch((err) => {
          this.groupPensionCertificatesErrorMsg = i18n.t(
            "global.service-no-available",
          );
          this.hasGroupPensionAnalysisData = true;
        });
    }
  };
}
export default createContext(new ContractStore());
