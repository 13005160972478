import * as React from "react";
import { Select, Button } from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const SelectPolicy = ({ items, current, onSelect }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const selectOptions = React.useMemo(
    () =>
      items.map(({ ProductName, Number }) => ({
        text: `${ProductName + " " + Number}`,
        value: Number,
      })),
    [items],
  );

  function handleSelect(event) {
    const selectedContractNumber = event.target.value;

    onSelect(selectedContractNumber);
  }

  if (!items.length) {
    return (
      <>
        <h2 className="no-payment-text">
          <span>{t("online.payment.option-not-available")}</span>
        </h2>
        <Button onClick={() => navigate("/home")} variant={"PrimaryMedium"}>
          {t("online.payment.return-original")}
        </Button>
      </>
    );
  }

  return (
    <>
      <h2>
        <span className="light">
          {t("online.payment.change-card-select-text")}
        </span>{" "}
      </h2>
      <div className="online-payment-container">
        <Select
          labelText={t("global.choose-insurance-policy")}
          name="select"
          onChange={handleSelect}
          options={selectOptions}
          testId="select"
          value={current}
          mb={"10px"}
        />
      </div>
    </>
  );
};

export { SelectPolicy };
