import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "@nn-design-system/react-component-library";

import ModalStore from "../../Stores/ModalStore";
import CheckBox from "../Inbox/CheckBox";
import { setDateFormatForDateTime } from "../CommonComp/Functions";

const OnlinePaymentMethodDetails = ({ paymentMethod, contractNumber }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { toggleTerms } = React.useContext(ModalStore);
  const [isChecked, setIsChecked] = React.useState(false);

  const { MaskedAccountNumber, ExpirationDate } = paymentMethod[contractNumber];
  const myUrl = isChecked ? "/online-payment/2" : "/online-payment";

  return (
    <>
      <div className="form-element right-padded">
        <span className="payBold">{t("contracts.insurance-number")}</span>
        <span> {contractNumber} </span>
      </div>
      <div className="form-element right-padded">
        <span className="payBold">{t("online.payment.card-number")} </span>
        <span> {MaskedAccountNumber} </span>
      </div>
      <div className="form-element right-padded">
        <span className="payBold">{`${t("global.expiry-date")}:`} </span>
        <span>{setDateFormatForDateTime(ExpirationDate)}</span>
      </div>
      <div className="form-element right-padded"></div>
      <div className="form-element checkbox nn">
        <label>{t("online.payment.accept-terms-use")}</label>
        <div className=" checkbox-container-register-3">
          <CheckBox
            id={"checkbox-register-3"}
            dataName={"useTerms"}
            data={[{ isChecked, setIsChecked }, "normal"]}
            innerText={t("online.payment.text-terms-use")}
          />
          <div className="anchor-modal">
            <a
              onClick={() => toggleTerms()}
              className="link"
              data-toggle="modal"
              data-target="#modal-terms"
            >
              {t("global.terms_of_use")}
            </a>
          </div>
        </div>
      </div>
      <div className="disclaimer-body">
        <Button
          mr={"20px"}
          onClick={() => navigate(myUrl)}
          variant={isChecked ? "PrimaryMedium" : "SecondaryMedium"}
        >
          {t("global.next")}
        </Button>
        {isChecked && (
          <span className="disclaimer1">
            {t("online.payment.redirection-msg")}
          </span>
        )}
      </div>
    </>
  );
};

export { OnlinePaymentMethodDetails };
